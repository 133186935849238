@font-face {
    font-family: 'Montserrat';
    src: url('Montserrat-Regular.woff2');
    src: local('Montserrat Regular'), local('Montserrat-Regular'),
    url('Montserrat-Regular.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}

@font-face {
   font-family: 'Montserrat';
   src: url('Montserrat-Bold.woff2');
   src: local('Montserrat Bold'), local('Montserrat-Bold'),
   url('Montserrat-Bold.woff2') format('woff2');
   font-weight: bold;
   font-style: normal;
}

@font-face {
   font-family: 'Montserrat';
   src: url('Montserrat-Medium.woff2');
   src: local('Montserrat Medium'), local('Montserrat-Medium'),
   url('Montserrat-Medium.woff2') format('woff2');
   font-weight: 500;
   font-style: normal;
}

@font-face {
   font-family: 'Montserrat';
   src: url('Montserrat-SemiBold.woff2');
   src: local('Montserrat SemiBold'), local('Montserrat-SemiBold'),
   url('Montserrat-SemiBold.woff2') format('woff2');
   font-weight: 600;
   font-style: normal;
}